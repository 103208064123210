import React from 'react';
import styled from '@emotion/styled';

/*---CVT---*/
import { CvtColorSchema } from '../../global/cvt-obj';
import { DeviceSizes } from '../../global/global-obj';
import { BlockStandard } from '../../components/cvt/layout/block-standard';

/*---Components---*/
import Hexagon from '../hexagonComponent';

/*---Objects---*/
import { PersonObj, PaddingObj, DiagonalObj } from '../../global/sharedObjects';

const HexDiv = styled.div`
  width: 30em;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 36px;
  @media (max-width: ${DeviceSizes.tablet}) {
    width: 30%;
  }
`;

const PhotoSize = '16';

const FlexDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  @media (max-width: ${DeviceSizes.tablet}) {
    flex-direction: column;
  }
`;
const TextDivLeftAlign = styled.div`
  width: 30%;
  text-align: left;
  @media (max-width: ${DeviceSizes.laptop}) {
    width: 100%;
  }
`;
const TextDivRightAlign = styled.div`
  width: 30%;
  @media (max-width: ${DeviceSizes.laptop}) {
    width: 100%;
    order: 2;
  }
  text-align: right;
`;

const CardUnternehmenGFuehrer = ({ textBernd, textMarcelo }: any) => {
  return (
    <BlockStandard
      padding={PaddingObj.PaddingIdle}
      diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
      zIndex={6}
      bgColor={CvtColorSchema.white}
    >
      <FlexDiv>
        <HexDiv>
          <Hexagon
            rotation
            photo={PersonObj.BerndMaurer.photo}
            photoSize={PhotoSize}
            alt={PersonObj.BerndMaurer.name}
          />
        </HexDiv>
        <TextDivLeftAlign>
          {textBernd}
          <br />
          <br />
          <i>
            {PersonObj.BerndMaurer.fullname}
            <br />
            {PersonObj.BerndMaurer.titel}
          </i>
        </TextDivLeftAlign>
      </FlexDiv>
      <br />
      <br />
      <FlexDiv>
        <TextDivRightAlign>
          {textMarcelo}
          <br />
          <br />
          <i>
            {PersonObj.MarceloEmmerich.fullname}
            <br />
            {PersonObj.MarceloEmmerich.titel}
          </i>
        </TextDivRightAlign>
        <HexDiv>
          <Hexagon
            rotation
            photo={PersonObj.MarceloEmmerich.photo}
            photoSize={PhotoSize}
            alt={PersonObj.MarceloEmmerich.fullname}
          />
        </HexDiv>
      </FlexDiv>
    </BlockStandard>
  );
};

/**
 * @description Returns the introduction of the CEOs.
 * @see /Unternehmen.js
 */

export default CardUnternehmenGFuehrer;
