import React from 'react';
import styled from '@emotion/styled';

/*---CVT---*/
import { CvtColorSchema } from '../global/cvt-obj';
import { DeviceSizes } from '../global/global-obj';

const GreenDiv = styled.div`
  margin-top: 10%;
  background-color: rgba(${CvtColorSchema.greenTransparent});
  clip-path: polygon(0 0, calc(100% - 40%) 0, calc(100% - 25%) 100%, 0 100%);
  @media (max-width: ${DeviceSizes.laptop}) {
    clip-path: none;
    background-color: rgba(${CvtColorSchema.greenTransparent});
  }
`;
const DivGreenText = styled.div`
  padding-bottom: 9em;
  padding-top: 4em;
  padding-left: 5%;
  padding-right: 40%;
  color: ${CvtColorSchema.fontBlack};
  @media (max-width: ${DeviceSizes.laptop}) {
    padding-right: 5%;
  }
`;

function PageblockPhotoAndGreen({ children }) {
  return (
    <GreenDiv>
      <DivGreenText>{children}</DivGreenText>
    </GreenDiv>
  );
}

export default PageblockPhotoAndGreen;
