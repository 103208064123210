import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import SYSTEM_STRINGS from '../../utility/data/system/systemStrings.yaml';
import { useLocation } from '@reach/router';

/*---Components---*/
import Layout from '../../components/skeleton_page/layout';
import Seo from '../../components/skeleton_page/seo';
import PageBlockPhotoAndGreen from '../../components/pageBlockPhotoAndGreen';
import CardKunden from '../../components/Kunden_comps/cardKunden';
import CardUnternehmenGFuehrer from '../../components/Unternehmen_comps/cardUnternehmenGFuehrer';
import AllianceTile from './CreateAllianceTile';
import PageblockIconsFlex from '../../components/pageBlockIconsFlex';
import { HexagonCard } from '../../components/hexagonCard';
import { LinkComponentExtern } from '../../components/linkComponent';

/*---Objects---*/
import { PaddingObj, DiagonalObj } from '../../global/sharedObjects';

/*---Images---*/
import UnternehmenGruppe from '../../images/Pics/pic_unternehmen_gruppe.jpg';
import CreateAllianceTileCms from './CreateAllianceTileCms';

/*---CVT---*/
import { CvtColorSchema, CvtBasicTheme } from '../../global';
import { Headline } from '../../components/cvt/data-display/headline';
import { useWindowDimensions } from '../../utility';
import { BlockStandard } from '../../components/cvt/layout/block-standard';
import { BlockTop } from '../../components/cvt/layout/block-top';
import { HtmlContent } from '../../components/basic/html-content';

import MarkdownIt from 'markdown-it';
const md = MarkdownIt({ html: true });

export const UnternehmenPageTemplate = ({
  title,
  subtitle,
  image,
  contentKompetenzfelder,
  contentAllianzen,
  contentGeschaeftsfuehrer,
  contentUSP,
}: {
  title: string;
  subtitle: string;
  image: any;
  contentKompetenzfelder: any;
  contentAllianzen: any;
  contentGeschaeftsfuehrer: any;
  contentUSP: any;
}) => {
  return (
    <section className="section section--gradient">
      <div
        className="full-width-image-container margin-top-0"
        style={{
          backgroundImage: `url(${
            !!image.childImageSharp ? image.childImageSharp.fluid.src : image
          })`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
        }}
      >
        <h1
          style={{
            color: '#6e6e6e',
          }}
        >
          {title}
        </h1>
        <h3
          style={{
            color: '#6e6e6e',
          }}
        >
          {subtitle}
        </h3>
      </div>
      <hr />
      <h2>{contentKompetenzfelder.headline}</h2>
      <br />
      {contentKompetenzfelder.content}
      <br />
      <hr />
      <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        <CreateAllianceTileCms data={contentAllianzen} />
      </div>
      <hr />
      <b>Bernd Maurer:</b>
      <br />
      {contentGeschaeftsfuehrer.textBM}
      <br />
      <br />
      <b>Marcelo Emmerich:</b>
      <br />
      {contentGeschaeftsfuehrer.textME}
      <br />
      <hr />
      <h2>{contentUSP.headline}</h2>
      <br />
      {contentUSP.content}
      <br />
    </section>
  );
};

UnternehmenPageTemplate.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  contentKompetenzfelder: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
  }),
  contentAllianzen: PropTypes.array,
  contentGeschaeftsfuehrer: PropTypes.shape({
    textBM: PropTypes.string,
    textME: PropTypes.string,
  }),
  contentUSP: PropTypes.shape({
    headline: PropTypes.string,
    content: PropTypes.string,
  }),
};

const UnternehmenPage = ({ data }: { data: any }) => {
  const { frontmatter } = data.markdownRemark;
  const location = useLocation();
  const featuredImage = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.resize
    : null;
  const zIndexRangeAlliance = Math.ceil(
    frontmatter.contentAllianzen.length / 2
  );
  const zIndexTotal = zIndexRangeAlliance + 10;
  const { width } = useWindowDimensions();

  return (
    <Layout>
      <Seo
        title={frontmatter.pageTitle}
        keywords={frontmatter.seoKeywords}
        description={frontmatter.pageDescription}
        image={featuredImage}
        pathname={location.pathname}
      />
      <BlockTop
        headline={frontmatter.title}
        subheadline={frontmatter.subtitle}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgImage={
          !frontmatter.image.childImageSharp &&
          frontmatter.image.extension === 'svg'
            ? frontmatter.image.publicURL
            : null
        }
        zIndex={zIndexTotal}
        padding={PaddingObj.PaddingStarter}
        color={CvtColorSchema.fontDarkGray}
      />
      <BlockStandard
        headline={frontmatter.contentKompetenzfelder.headline}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgColor={CvtColorSchema.white}
        zIndex={zIndexTotal - 1}
        padding={PaddingObj.PaddingIdle}
        buttonLink={SYSTEM_STRINGS.LINKS.INTERN.KOMPETENZFELDER}
        buttonText={SYSTEM_STRINGS.LINKS.NAME.KOMPETENZFELDER}
        textAlignment={CvtBasicTheme.align.right as any}
      >
        <HtmlContent
          dangerouslySetInnerHTML={md.render(
            frontmatter.contentKompetenzfelder.content
          )}
          style={CvtBasicTheme.htmlContent.general}
        />
        <PageblockIconsFlex margintop="1%">
          {frontmatter.contentKompetenzfelder.hexagonBlock.map(
            (item: any, index: number) => {
              return (
                <React.Fragment key={'Kompetenzfelder-' + index}>
                  <HexagonCard
                    link={item.link}
                    graphic={
                      !item.image.childImageSharp &&
                      item.image.extension === 'svg'
                        ? item.image.publicURL
                        : null
                    }
                    alt={item.headline}
                    iconsize={'6'}
                    length={'short'}
                    headline={item.headline}
                    description={item.content}
                    linkText={SYSTEM_STRINGS.GENERAL.WORDS.MORE}
                  />
                </React.Fragment>
              );
            }
          )}
        </PageblockIconsFlex>
      </BlockStandard>

      {frontmatter.contentAllianzen.length === 1 ? (
        <BlockStandard
          headline={frontmatter.contentAllianzen[0].headline}
          bgColor={CvtColorSchema.bgGray}
          diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
          zIndex={zIndexTotal - 2}
          padding={PaddingObj.PaddingIdle}
          color={CvtColorSchema.fontDarkGray}
          buttonLink={frontmatter.contentAllianzen[0].link}
          buttonText={frontmatter.contentAllianzen[0].btnContent}
        >
          <HtmlContent
            dangerouslySetInnerHTML={md.render(
              frontmatter.contentAllianzen[0].content
            )}
            style={CvtBasicTheme.htmlContent.general}
          />
          <LinkComponentExtern linkTo={frontmatter.contentAllianzen[0].link}>
            <img
              height={width > 768 ? '80px' : '40px'}
              width="auto"
              src={frontmatter.contentAllianzen[0].image.publicURL}
              alt={frontmatter.contentAllianzen[0].alt}
            />
          </LinkComponentExtern>
        </BlockStandard>
      ) : (
        <AllianceTile
          data={frontmatter.contentAllianzen}
          zIndex={zIndexTotal - 2}
        />
      )}
      <CardUnternehmenGFuehrer
        textBernd={frontmatter.contentGeschaeftsfuehrer.textBM}
        textMarcelo={frontmatter.contentGeschaeftsfuehrer.textME}
      />
      <BlockStandard
        bgImage={UnternehmenGruppe}
        padding={PaddingObj.PaddingGreenDiv}
        diagonalAlignment={DiagonalObj.DiagonalLeftUp as any}
        zIndex={5}
        color={CvtColorSchema.white}
      >
        <PageBlockPhotoAndGreen>
          <Headline variant="h2" text={frontmatter.contentUSP.headline} />
          <HtmlContent
            dangerouslySetInnerHTML={md.render(frontmatter.contentUSP.content)}
            style={CvtBasicTheme.htmlContent.general}
          />
        </PageBlockPhotoAndGreen>
      </BlockStandard>
      <BlockStandard
        buttonLink={SYSTEM_STRINGS.LINKS.INTERN.KUNDEN}
        buttonText={SYSTEM_STRINGS.LINKS.NAME.KUNDEN}
        diagonalAlignment={DiagonalObj.DiagonalLeftBelow as any}
        bgColor={CvtColorSchema.white}
        padding={PaddingObj.PaddingIdle}
        color={CvtColorSchema.fontDarkGray}
        zIndex={4}
      >
        <CardKunden />
      </BlockStandard>
    </Layout>
  );
};

UnternehmenPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default UnternehmenPage;

export const unternehmenPageQuery = graphql`
  query UnternehmenPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        contentKompetenzfelder {
          content
          headline
          hexagonBlock {
            headline
            link
            image {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
          }
        }
        contentAllianzen {
          content
          subheadline
          headline
          alt
          image {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
            extension
            publicURL
          }
          btnContent
          link
        }
        contentUSP {
          content
          headline
        }
        contentGeschaeftsfuehrer {
          textBM
          textME
        }
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid
            }
          }
          extension
          publicURL
        }
        seoKeywords
        pageTitle
        pageDescription
        subtitle
        title
        featuredImage {
          childImageSharp {
            resize(width: 900) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`;
