import React from 'react';

const CreateAllianceTileCms = ({ data, cmsPreview }) => {
  let filterArr = [];

  for (let i = 0; i < data.length; i = i + 4) {
    filterArr.push(i);
    filterArr.push(i + 3);
  }

  return data.map((alliance, index) => {
    return (
      <div
        style={
          filterArr.includes(index)
            ? {
                width: '50%',
              }
            : { backgroundColor: '#6e6e6e', color: 'white', width: '50%' }
        }
      >
        <button>{alliance.btnContent}</button>
        <h2>{alliance.headline}</h2>
        <h3>{alliance.subheadline}</h3>
        {alliance.content}
        <br />
        <img
          src={alliance.image}
          alt={alliance.alt}
          width="20%"
          height="auto"
        />
      </div>
    );
  });
};

export default CreateAllianceTileCms;
